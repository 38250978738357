import { useAccordion } from "@ally/metronome-ui";
import { CSSProperties } from "react";
import { DocumentsYouMayNeedContent, ListStyle } from "../ResourcedAccordionStyled";

const DocumentsYouMayNeed: React.FC = () => {
    const { handleFocus } = useAccordion();
    const continueStyle: CSSProperties = {
      fontWeight: 700,
    };
  
    return (
      <>
        <DocumentsYouMayNeedContent ref={handleFocus} tabIndex={-1}>
          For document details, enter the applicant's information and select{" "}
          <span style={continueStyle}>Continue.</span>
        </DocumentsYouMayNeedContent>
  
        <ul>
          <ListStyle> Current Year Financial Statements</ListStyle>
          <ListStyle> Two Previous Year-End Financial Statements</ListStyle>
          <ListStyle> Guarantor Information</ListStyle>
          <ListStyle> Bank Statements</ListStyle>
          <ListStyle> Cross Default / Cross Collateralization Agreement</ListStyle>
        </ul>
      </>
    );
  };

  export default DocumentsYouMayNeed