import styled from "styled-components";

export const Container = styled.nav`
  align-items: center;
  background: #650360;
  height: 68px;
  position: relative;
  @media (min-width: 1330px) {
    display: flex;
    top: 0;
    position: relative;
  }
`;

export const BodyContainer = styled.div`
  min-height: calc(100vh - 100px);
  @media (max-width: 639px) {
    min-height: calc(100vh - 195px);
  }
`;

export const FooterContainer = styled.div`
  display: none;

  @media (min-width: 1330px) {
    display: flex;
    bottom: 100px;
    position: relative;
    background-color: #f8f8f8;
    border-top: 1px solid #dfdfdf;
  }
`;
