import { muiDownload } from "@ally/metronome-icons/dist/cjs";
import { useAccordion } from "@ally/metronome-ui";
import { DocumentCategory, DocumentCategoryAriaLabels } from "../../Model/Constants/DocumentCategory";
import {
  RequiredFileUploadParagraphStyle,
  RequiredFileUploadUnorderedListStyle,
  RequiredFileUploadListStyle,
  AccordionContentStyle,
} from "../RequiredDocAccordionStyled";
import {
  StyledIcon,
  PdfDownloadLink,
  PdfLabel,
} from "../ResourcedAccordionStyled";
import { UploadFileComponent } from "../UploadFileComponent";

const AllyCommercialService: React.FC = () => {
  const mainContent =
    "Upload a completed Commercial Services Credit Line Application signed by an authorized individual of the applicant. Make sure to provide the exact legal business name of the applicant in the required fields.";

  const requiredBulletOne =
    "Complete the Dealer Information section if the information indicated is available";

  const requiredBulletTwo =
    "If multiple entities are applying for access to the credit line, complete and upload a credit application and financial statements for each entity that’s applying";

  const { handleFocus } = useAccordion();

  return (
    <>
    <AccordionContentStyle>
      <RequiredFileUploadParagraphStyle ref={handleFocus} tabIndex={-1}>
          {mainContent}
        </RequiredFileUploadParagraphStyle>
        <RequiredFileUploadUnorderedListStyle>
          <RequiredFileUploadListStyle>
            {requiredBulletOne}
          </RequiredFileUploadListStyle>
          <RequiredFileUploadListStyle>
            {requiredBulletTwo}
          </RequiredFileUploadListStyle>
        </RequiredFileUploadUnorderedListStyle>
        <div style={{ display: "flex" }}>
          <StyledIcon muiIcon={muiDownload} fill="#0071C4" ariaHidden />
          <div>
            <PdfDownloadLink
              download
              href=" https://www.ally.com/resources/pdf/dealer/online/credit-line-application.pdf "
              rel="noreferrer noopener"
              target="_blank"
              aria-label="Download Ally Commercial Services Credit Line Application (PDF)"
            >
              Download Ally Commercial Services Credit Line Application
            </PdfDownloadLink>
            <PdfLabel>&nbsp;(PDF)</PdfLabel>
          </div>
        </div>
      </AccordionContentStyle>
      <UploadFileComponent from={DocumentCategory.CreditLineApplication} arialLabel={DocumentCategoryAriaLabels.CreditLineApplicationAriaLabel} />
    </>
  );
};

export default AllyCommercialService;
