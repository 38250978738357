import styled from "styled-components";

export const AdditionalFileUploadParagraphStyle = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: normal;
  margin-bottom: 8px;
  outline: none;
`;
export const AdditionalFileUploadUnorderedListStyle = styled.ul`
  padding-left: 30px;
  margin-bottom: 8px;
`;
export const AdditionalFileUploadListStyle = styled.li`
  font-size: 14px;
  line-height: 21px;
  margin: 0 8px;
  padding-bottom: 4px;
  padding-left: 4px;
`;
