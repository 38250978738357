import { muiDownload } from "@ally/metronome-icons/dist/cjs";
import { useAccordion } from "@ally/metronome-ui";
import {
  StyledIcon,
  CreditLineApplicationFormContent,
  PdfDownloadLink,
  PdfLabel,
} from "../ResourcedAccordionStyled";

const CreditLineApplicationForm: React.FC = () => {
  const { handleFocus } = useAccordion();
  return (
    <>
        <CreditLineApplicationFormContent
          ref={handleFocus}
          tabIndex={-1}
        >
          The application form must be completed and signed by an authorized
          individual of the applicant prior to uploading.
        </CreditLineApplicationFormContent>
        <StyledIcon muiIcon={muiDownload} fill="#0071C4" ariaHidden />
        <PdfDownloadLink
          download
          href=" https://www.ally.com/resources/pdf/dealer/online/credit-line-application.pdf "
          style={{ textDecoration: "none" }}
          rel="noreferrer noopener"
          target="_blank"
          id="downloadCreditLineApplication"
        >
          Download Ally Commercial Services Credit Line Application
        </PdfDownloadLink>
        <PdfLabel>&nbsp;(PDF)</PdfLabel>
    </>
  );
};

export default CreditLineApplicationForm;
