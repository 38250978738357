import { useEffect, useState } from "react";
import { DocumentCategory } from "../Model/Constants/DocumentCategory";
import {
  RequiredDocAccordionComponent,
  RequiredDocAccordionWrapper,
} from "./RequiredDocAccordionStyled";
import AdditionalStatementAccordion from "./SupportingDocumentsItems/AdditionalStatementAccordion";
import BankStatementAccordion from "./SupportingDocumentsItems/BankStatementAccordion";
import CollateralDocAccordion from "./SupportingDocumentsItems/CollateralDocAccordion";

const SupportingDocumentAccordion: React.FC<any> = (props: any) => {
  const [allUploaded, setAllUploaded] = useState(props.allUploaded);
  const [categoryUploadedStatuses, setCategoryUploadedStatuses] = useState(props.uploadStatus)

  useEffect(() => {
    setAllUploaded(props.allUploaded);
    setCategoryUploadedStatuses(props.uploadStatus);
  }, [props.allUploaded, props.uploadStatus]);

  return (
    <>
      {!allUploaded && (
        <RequiredDocAccordionWrapper mode="CONTAINER">
          {categoryUploadedStatuses && !categoryUploadedStatuses[DocumentCategory.BankStatement] && (
            <RequiredDocAccordionComponent
              key="1"
              title="Bank Statements"
              content={{
                mode: "CONTAINER",
                payload: <BankStatementAccordion />,
              }}
            />
          )}
          {categoryUploadedStatuses && !categoryUploadedStatuses[DocumentCategory.CrossDefaultCrossCollateralizationAgreement] && (
            <RequiredDocAccordionComponent
              key="2"
              title="Cross Default / Cross Collateralization Agreement"
              content={{
                mode: "CONTAINER",
                payload: <CollateralDocAccordion />,
              }}
            />
          )}
          {categoryUploadedStatuses && !categoryUploadedStatuses[DocumentCategory.AdditionalDocument] && (
            <RequiredDocAccordionComponent
              key="3"
              title="Additional Documents"
              content={{
                mode: "CONTAINER",
                payload: <AdditionalStatementAccordion />,
              }}
            />
          )}
        </RequiredDocAccordionWrapper>
      )}
    </>
  );
};

export default SupportingDocumentAccordion;
