import { DropdownMenu, MuiIcon } from "@ally/metronome-ui";
import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background: #650360;
  height: 68px;
  position: relative;
  top: 0;
  z-index: 3;
  @media (min-width: 1330px) {
    width: 1330px;
    margin: 0 auto;
    bottom: 0;
  }
`;

export const Logo = styled.div`
  margin-left: 10px;
  font-size: 1.5em;
  margin-top: 15px;
  margin-bottom: auto;

  @media (max-width: 639px) {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
`;

export const LogoSidNav = styled.div`
  font-size: 1.5em;
  margin-bottom: 20px;
  @media (max-width: 639px) {
    position: absolute;
    top: 30%;
    left: 52%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
`;

export const LogoImg = styled.img`
  align-items: center;
  height: 37px;
`;

export const MobileMenu = styled.div`
  display: none;
  @media (max-width: 639px) {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
  }

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
  }
`;

export const MobileMenuText = styled.p`
  outline: none;
  font-size: 10px;
  line-height: 12px;
  align: left;
  color: white;
  &:focus {
    outline: none;
    box-shadow: 0xp 0px 2px red;
  }
`;

export const Menu = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 639px) {
    display: none;
  }
`;

export const MenuItem = styled.div`
  outline: none;
  margin-right: 5px;
  font-size: 1em;
`;

export const LogoLink = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

export const IconButton = styled.button`
  cursor: pointer;
  background: #650360;
`;

export const MenuLink = styled.div`
  text-decoration: none;
  cursor: pointer;
  color: white;
  font-size: 14px;
  line-height: 17px;
  padding-right: 10px;


  &:hover {
    color: #f9f9f9;
  }
`;

export const SubMenuLink = styled.div`
  text-decoration: none;
  cursor: pointer;
  color: white;
  font-size: 14px;
  line-height: 17px;
  padding-right: 10px;
  outline: none;
  &:focus {
    outline: none;
  }
  link {
    outline: none;
  }
  &:hover {
    color: #f9f9f9;
  }
`;

export const SubHeader = styled.p`
  font-size: 14px;
  font-weight: normal;
  color: #2a2a2a;
  padding-top: 10px;
`;

export const StyledIcon = styled(MuiIcon)`
  padding-left: 5px;
  padding-top: 5px;
  height: 17px;
`;

export const StyledDropdownContainer = styled(DropdownMenu.MenuContainer)`
  height: 182.14px;
  width: 253px;
  padding-bottom: 20px;
  margin-right: -5px;
`;

export const ContactStyle = styled.div``;
