import React, { useEffect, useState } from "react";
import AllyCommercialService from "./RequiredAccordionItems/AllyCommercialService";
import CurrentYearFinancialStatements from "./RequiredAccordionItems/CurrentYearFinancialStatements";
import {
  RequiredDocAccordionWrapper,
  RequiredDocAccordionComponent,
} from "./RequiredDocAccordionStyled";
import GuarantorInformation from "./RequiredAccordionItems/GuarantorInformation";
import TwoPreviousYearEndStatement from "./RequiredAccordionItems/TwoPreviousYearEndStatement";
import { DocumentCategory } from "../Model/Constants/DocumentCategory";

const RequiredDocAccordion: React.FC<any> = (props: any) => {
  const [allUploaded, setAllUploaded] = useState(props.allUploaded);
  const [categoryUploadedStatuses, setCategoryUploadedStatuses] = useState(props.uploadStatus);
  useEffect(() => {
    setAllUploaded(props.allUploaded);
    setCategoryUploadedStatuses(props.uploadStatus);
  }, [props.allUploaded, props.uploadStatus]);

  return (
    <>
    {!allUploaded && categoryUploadedStatuses && (
      <RequiredDocAccordionWrapper mode="CONTAINER">
        {categoryUploadedStatuses && !categoryUploadedStatuses[DocumentCategory.CreditLineApplication] && (
          <RequiredDocAccordionComponent
            key="1"
            title="Ally Commercial Services Credit Line Application"
            content={{
              mode: "CONTAINER",
              payload: <AllyCommercialService />,
            }}
          />
        )} 
        {categoryUploadedStatuses && !categoryUploadedStatuses[DocumentCategory.CurrentYearFinancialStatement] && (
          <RequiredDocAccordionComponent
            key="2"
            title="Current Year Financial Statements"
            content={{
              mode: "CONTAINER",
              payload: <CurrentYearFinancialStatements />,
            }}
          />
        )}
        {categoryUploadedStatuses && !categoryUploadedStatuses[DocumentCategory.PreviousYearEndFinancialStatement] && (
          <RequiredDocAccordionComponent
            key="3"
            title="Two Previous Year-End Financial Statements"
            content={{
              mode: "CONTAINER",
              payload: <TwoPreviousYearEndStatement />,
            }}
          />
        )}
        {categoryUploadedStatuses && !categoryUploadedStatuses[DocumentCategory.GuarantorInformation] && (
        <RequiredDocAccordionComponent
          key="4"
          title="Guarantor Information"
          content={{
            mode: "CONTAINER",
            payload: <GuarantorInformation />,
          }}
        />
        )}

      </RequiredDocAccordionWrapper>
      )}
    </>
  );
};

export default RequiredDocAccordion;
