import http from "../../http-common";
import { UploadUrlRequest } from "./model/uploadUrlRequest";
import { AxiosResponse } from "axios";
import { UploadStatusResponseDto } from "./model/UploadStatusResponse.dto";
import { UploadUrlResponseDto } from "./model/UploadUrlResponse.dto";
import { CategoryUploadStatusList } from "../../components/Model/CategoryUploadStatusList";
import { DocumentCategory } from "../../components/Model/Constants/DocumentCategory";
import { getType } from "mime";
import { UploadStatusRequestDto } from "./model/UploadStatusRequest.dto";

class DocDocService {
    // Singleton Design Pattern
    static instance: DocDocService;
    
    private constructor() {}

    public static getInstance(): DocDocService {
        if (!DocDocService.instance) {
            DocDocService.instance = new DocDocService();
        }
        return DocDocService.instance;
    }

    public uploadDocument(request: UploadUrlRequest, file: File): Promise<AxiosResponse> {
        return this.getSignedUrl(request).then((uploadUrlDto) => this.uploadDoc(file, uploadUrlDto));
    }

    public async getStatus(phoneNumber: string, email: string): Promise<CategoryUploadStatusList> {
        const endpoint = "upload-status";
        const req = { phoneNumber,email } as UploadStatusRequestDto;
        const options = {
          headers: {
            'Authorization': "allow"
          }            
        };
        
        return http.post<UploadStatusResponseDto>(endpoint, req, options)
          .then((response) => this.mapStatusResponse(response.data));
    }

    private getSignedUrl(request: UploadUrlRequest): Promise<UploadUrlResponseDto> {
        const endpoint = "upload-url";
        const options = {
          headers: {
            'Authorization': "allow"
          }            
        };
        
        return http.post<UploadUrlResponseDto>(endpoint, request, options)
          .then((response) => response.data)
    }

    private uploadDoc(file: File, uploadUrlDto: UploadUrlResponseDto): Promise<AxiosResponse> {
      const contentType = getType(file.name);
      const options = {
        headers: {
          'x-amz-server-side-encryption': uploadUrlDto.serverSideEncryption,
          'x-amz-server-side-encryption-aws-kms-key-id': uploadUrlDto.sseKmsKeyId,
          "Content-type": contentType,
        }            
      };
      
      return http.put(uploadUrlDto.url, file, options);
    }

    private mapStatusResponse(response: UploadStatusResponseDto): CategoryUploadStatusList {
      let categoryUploadStatuses: CategoryUploadStatusList = {};
      for (const category of Object.values(DocumentCategory)) {
        const categoryUploaded = response.documents.find(doc => doc.category === category)?.uploaded;
        
        categoryUploadStatuses[category] = categoryUploaded;
      }

      return categoryUploadStatuses;
    }
}

export default DocDocService;
