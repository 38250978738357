import { AccordionComponent, AccordionWrapper } from "@ally/metronome-ui";
import styled from "styled-components";

export const NeedHelpStyledAccordionComponent = styled(AccordionComponent)`
  padding-top: 10px;
  @media (min-width: 1330px) {
    max-width: 1330px;
  }
  
  div[data-accordion-component="AccordionItemPanel"] {
    font-weight: 400;
    font-size: 16px;
    width: 327.422px;
    line-height: 24px;
  }
`;

export const NeedHelpStyledAccordionWrapper = styled(AccordionWrapper)`
  padding-left: 21px;
  margin-right: 44px;
  margin-top: 18px;
  border-left: 1px solid #dddddd;
  position: absolute;
  right: 0;

  @media (max-width: 1023px) {
    display: none;
  }

  @media (min-width: 1330px) {
    max-width: 1330px;
  }
  
  div[data-accordion-component="AccordionItemHeading"] {
    div[data-accordion-component="AccordionItemButton"] > div > div > div > svg {
      margin-left: 8px;
    }
`;

export const NeedHelpTextStyle = styled.h3`
  font-size: 18px;
  color: #2a2a2a;
  line-height: 23.4px;
  height: 24px;
  padding-bottom: 8px;
  padding-left: 8px;
  @media (max-width: 767px) {
    display: none;
    padding-top: 10px;
  }
`;

export const FileUploadParagraphStyle = styled.p`
  font-size: 14px;
  line-height: 21px;
  margin-top: 8px;
  margin-bottom: 8px;
  outline: none;
`;

export const FileUploadListStyle = styled.li`
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 4px;
`;

export const FileUploadUnorderedListStyle = styled.ul`
  padding-left: 30px;
  margin-bottom: 4px;
`;

export const AlternativeDocumentsInfoStyle = styled.p`
  font-size: 14px;
  line-height: 21px;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
`;
export const AlternativeDocumentsEmailInfoStyle = styled.a`
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-decoration: none;
  color: #0071c4;
`;

export const ContentHidden = styled.p`
position: absolute;
position: absolute !important;
width: 1px !important;
height: 1px !important;
padding: 0 !important;
margin: -1px !important;
overflow: hidden !important;
clip: rect(0,0,0,0) !important;
white-space: nowrap !important;
border: 0 !important;

`
