import { useAccordion } from "@ally/metronome-ui";
import {
  FileUploadParagraphStyle,
  FileUploadUnorderedListStyle,
  FileUploadListStyle,
  ContentHidden,
} from "../NeedHelpAccordionStyled";

const FileUploadRequirements: React.FC = () => {
  const { handleFocus } = useAccordion();
  const uploadContent = `File names can't exceed 60 characters or contain spaces, @ # $ " " / \\ or other special characters`;
  const maxUploadMb = process.env.REACT_APP_MAX_UPLOAD_MB
    ? parseInt(process.env.REACT_APP_MAX_UPLOAD_MB)
    : 25;
    
  return (
    <>
      <FileUploadParagraphStyle ref={handleFocus} tabIndex={-1}>
        Before uploading documents, keep in mind:
      </FileUploadParagraphStyle>
      <ContentHidden>File names can't exceed 60 characters or contain spaces, @ # $ double quotes, forward slash, back slash or other special characters.</ContentHidden>
      <FileUploadUnorderedListStyle >
        <FileUploadListStyle aria-hidden="true">
            {uploadContent}         
        </FileUploadListStyle>
        <FileUploadListStyle>
          We only accept PDF, HEIC, JPG or PNG files
        </FileUploadListStyle>
        <FileUploadListStyle>
          Total file size can’t exceed {maxUploadMb} MB
        </FileUploadListStyle>
      </FileUploadUnorderedListStyle>
    </>
  );
};

export default FileUploadRequirements;
