import styled from "styled-components";

import {
  AccordionComponent,
  AccordionWrapper,
  MuiIcon,
  TextBody,
} from "@ally/metronome-ui";

export const StyledAccordionComponent = styled(AccordionComponent)`
  padding-top: 10px;
  margin-left: 
  div[data-accordion-component="AccordionItemPanel"] {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    position: relative;
  }

  div[data-accordion-component="AccordionItemHeading"] {
    margin-right: 4px;

    div[data-accordion-component="AccordionItemButton"] > div > div > div > svg {
      margin-left: 8px;
      width: 12px;
      height: 10px;
      margin-top: 2px;
    }
    
    div[data-accordion-component="AccordionItemButton"] > div > div > div > p {
      font-size: 16px;
    }

`;

export const StyledAccordionWrapper = styled(AccordionWrapper)`
  padding-left: 21px;
  border-left: 1px solid #dddddd;
  margin-top: 160px;

  @media (max-width: 767px) {
    margin-top: 0;
    margin-left: 24px;
    border-left: 0;
    width: 100%;
    border-top: 1px solid #979797;
    padding-top: 0;
    padding-left: 0;
    padding-right: 65px;
  }

  @media (max-width: 767px) and (min-height: 920px) {
    margin-bottom: calc(100vh - 920px);
  }

  @media (max-width: 639px) {
    margin-top: 0;
    margin-left: 24px;
    border-left: 0;
    border-top: 1px solid #979797;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
    margin-right: 14px;
  }

  @media (max-width: 500px) {
    margin-right: 0;
  }
`;

export const PdfDownloadLink = styled.a`
  font-size: 14px;
  text-decoration: none;
  font-weight: bold;
  line-height: 24px;
  color: #0071c4;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

export const PdfLabel = styled.span`
  font-size: 14px;
  text-decoration: none;
  font-weight: 400;
  line-height: 24px;
  color: #2a2a2a;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

export const StyledIcon = styled(MuiIcon)`
  padding-right: 4px;
  height: 20px;
  width: 20px;
  padding-top: 4px;
  font-weight: bold;
  margin-bottom: -2px;
`;

export const OverrideTextBody = styled(TextBody)`
  margin-top: 0;
  display: flex;
  padding-top: 5px;
  text-decoration: none;
`;

export const ResourceStyle = styled.h2`
  font-size: 18px;
  color: #2a2a2a;
  line-height: 23.4px;
  width: 84px;
  height: 24px;
  padding-bottom: 8px;
  padding-left: 8px;
  @media (max-width: 767px) {
    padding-top: 16px;
    margin-bottom: 12px;
    margin-top: 4px;
  }
`;

export const ListStyle = styled.li`
  font-size: 14px;
  line-height: 24px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 20px;
  color: #2a2a2a;
  outline: none;
`;

export const OtherHelpContentStyle = styled(TextBody)`
  font-size: 14px;
  padding-top: 11px;
  padding-bottom: 11px;
  outline: none;
`;
export const FinanceAndLeaseLink = styled.a`
  text-decoration: none;
  color: #0071c4;
`;

export const CreditLineApplicationFormContent = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #2a2a2a;
  outline: none;
`;

export const DocumentsYouMayNeedContent = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #2a2a2a;
  outline: none;
`;
