import { useAccordion } from "@ally/metronome-ui";
import { DocumentCategory, DocumentCategoryAriaLabels } from "../../Model/Constants/DocumentCategory";
import {
  AccordionContentStyle,
  RequiredFileUploadListStyle,
  RequiredFileUploadUnorderedListStyle,
} from "../RequiredDocAccordionStyled";
import { UploadFileComponent } from "../UploadFileComponent";
import { CollateralUploadParagraphStyle } from "./CollateralDocAccordionStyled";

const CollateralDocAccordion = () => {
  const { handleFocus } = useAccordion();

  const collateralContent = `For new credit line customers, or in cases where guarantor information is required, each applicant needs to complete an executed Cross Default / Cross Collateralization Agreement to activate the credit line.`;

  const requiredBulletOne = `The applicant’s dealer or Ally product specialist will provide this document`;

  const requiredBulletTwo = `The document must be printed and signed in ink`;

  return (
    <>
      <AccordionContentStyle>
        <CollateralUploadParagraphStyle ref={handleFocus} tabIndex={-1}>
          {collateralContent}
        </CollateralUploadParagraphStyle>
        <RequiredFileUploadUnorderedListStyle>
          <RequiredFileUploadListStyle>
            {requiredBulletOne}
          </RequiredFileUploadListStyle>
          <RequiredFileUploadListStyle>
            {requiredBulletTwo}
          </RequiredFileUploadListStyle>
        </RequiredFileUploadUnorderedListStyle>
      </AccordionContentStyle>
      <UploadFileComponent
        from={DocumentCategory.CrossDefaultCrossCollateralizationAgreement}
        arialLabel={DocumentCategoryAriaLabels.CrossDefaultCrossCollateralizationAgreementAriaLabel}
      />
    </>
  );
};

export default CollateralDocAccordion;
