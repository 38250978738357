import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', 'Lato Regular', 'Lato Bold';

  }

  :not(span, a, path) {
    color: #2A2A2A;
  }

  #root {
    margin: 0 auto;
  }

  html {
    background: #f9f9f9 no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;    
    -webkit-overflow-scrolling: touch;
  }
  
`;