export class UploadUrlRequest {
    filename: string;
    companyName: string;
    phoneNumber: string;
    email: string;
    documentType: string;
    contactName: string;

    constructor(filename: string,
      companyName: string,
      phoneNumber: string,
      email: string,
      documentType: string,
      contactName: string) {
        this.filename = filename;
        this.companyName = companyName;
        this.phoneNumber = phoneNumber;
        this.email = email;
        this.documentType = documentType;
        this.contactName = contactName;
    }
}