import { StringFormatUtility } from "../../../Utility/string-format.utility";
import { LabelStyle, UnSuccessfullyUploadDivStyle } from "../RequiredDocAccordionStyled";

interface unSuccessfulChildProps {
    item: any;
}

export const UploadError: React.FC<unSuccessfulChildProps> = (
  props: unSuccessfulChildProps
) => {
  const fileName = props.item.name;
  const fileSize = StringFormatUtility.generateFileSizeString(props.item.size);
  const displayMessage = `${fileName} (${fileSize}) was not submitted.`;

  window.allytm.event('customError', displayMessage, 400);
        
  
  return (
    <UnSuccessfullyUploadDivStyle>
      <LabelStyle>{displayMessage}</LabelStyle>
    </UnSuccessfullyUploadDivStyle>
  );
};
