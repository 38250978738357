import styled from 'styled-components';

export const SideNav = styled.div`
background: #650360;
width: 243px;
height: 112vh;
position: absolute;
top: 68px;
left: 0;
bottom: 0;
transition: 850ms;
flex-direction: column;
`

export const SideSection = styled.div`
border-top: rgba(255,255,255,0.25) 1px solid;
height: 37px;
display: flex;
justify-content: flex-start;
align-items: center;
padding-left: 25px;

`
export const SideSectionHours = styled.div`
border-top: rgba(255,255,255,0.25) 1px solid;
border-bottom: rgba(255,255,255,0.25) 1px solid;
height: 144px;
display: flex;
justify-content: flex-start;
align-items: left;
padding-left: 25px;
flex-direction: column;
background-color: rgba(0, 0, 0, 0.25);
`

export const SectionText = styled.a`
font-size: 14px;
line-height: 17x;
text-decoration: none;
font-weight: bold;
color: #fafafa;
padding-bottom: 2px;
padding-top: 10px;
`

export const SideNavContactDayContent = styled.p`
font-size: 14px;
line-height: 17px;
font-weight: normal;
padding-top: 8px;
color: white;
`
export const SideNavContactHoursContent = styled.p`
font-size: 14px;
line-height: 17px;
font-weight: normal;
padding-top: 2px;
color: white;
`

export const SideEmailText = styled.p`
font-size: 14px;
line-height: 17px;
font-weight: bold;
padding-top: 18px;
font-weight: bold;
padding-bottom: 2px;
color: white;
`
export const SideEmailLink = styled.a`
font-size: 14px;
line-height: 24px;
font-weight: bold;
text-decoration: none;
color: #8BD3F5;
`
export const SideNavMobileMenu = styled.div`
  display: none;
  overflow: hidden;

  @media (max-width: 639px) {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
  }

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
  }
`;