import { AccordionComponent, AccordionWrapper } from "@ally/metronome-ui";
import styled from "styled-components";

export const ContactStyledAccordionComponent = styled(AccordionComponent)`
  padding-top: 10px;
  div[data-accordion-component="AccordionItemPanel"] {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  div[data-accordion-component="AccordionItemButton"] > div > div > div > svg {
    margin-left: 8px;
  }
`;

export const ContactStyledAccordionWrapper = styled(AccordionWrapper)`
  padding-right: 42.5px;
  margin-left: 35px;

  @media (max-width: 767px) {
    margin-top: 0px;
    border-left: 0;
    padding-top: 5px;
    margin-left: 12px;
  }
`;

export const ContactNameStyle = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: #2a2a2a;
  margin-top: 10px;
  margin-bottom: 4px;
  word-wrap: break-word;
  outline: none;

`;

export const ContactEmailStyle = styled.p`
  display: block;
  font-size: 14px;
  line-height: 21px;
  color: #2a2a2a;
  margin-bottom: 4px;
  word-wrap: break-word;
  overflow-wrap: anywhere;
`;

export const ContactPhoneNumberStyle = styled.p`
  height: 21px;
  font-size: 14px;
  line-height: 21px;
  color: #2a2a2a;
  margin-bottom: 4px;
`;

export const ContactDetailStyleDiv = styled.div`
  display: inline-block;
`;
