import { FocusOn } from "react-focus-on";
import React, { useEffect, useRef } from "react";
import { CgClose } from "react-icons/cg";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {
  SideSection,
  SectionText,
  SideNav,
  SideSectionHours,
  SideNavContactHoursContent,
  SideEmailText,
  SideEmailLink,
  SideNavContactDayContent,
  SideNavMobileMenu,
} from "./SideNavStyled";
import { LogoImg, LogoLink, LogoSidNav } from "./NavigationStyled";
import logo from "../../assets/ally_white.png";


interface childProps {
  updateState: (arg: string) => void;
}

const SideNavComponent: React.FC<childProps> = (props: childProps) => {
  const [openContactUsTab, setOpenContactUsTab] = React.useState(true);
  const closeSideNavRef = React.useRef(null);

  const openSideMenuTab = () => {
    setOpenContactUsTab(!openContactUsTab);
  };

  const mobileMenuRef = useRef<HTMLDivElement>(null);

  const clickOutsideHandler = () => {
    props.updateState("closed");
  };

  useOnClickOutside(mobileMenuRef, clickOutsideHandler);

  const closeIcon = <CgClose size="30px" color="white" />;

  useEffect(() => {
    closeSideNavRef.current?.focus();
  });

  return (
    <FocusOn>
      <div ref={mobileMenuRef}>
        <SideNavMobileMenu
          data-test-id="mobile-close-icon"
          aria-label="close menu"
          ref={closeSideNavRef}
          role="button"
          tabIndex={0}
          onClick={() => {
            props.updateState("closed");
          }}
          onKeyPress={() => {
            props.updateState("clwosed");
          }}
        >
          {closeIcon}
        </SideNavMobileMenu>
        <LogoSidNav>
          <LogoLink href="http://www.ally.com" id="ally-logo-nav" rel="noreferrer noopener">
            <LogoImg src={logo} alt="ally logo go to ally.com" />
          </LogoLink>
        </LogoSidNav>

        <SideNav>
          <SideSection>
            <SectionText href="http://www.ally.com/help">Help</SectionText>
          </SideSection>
          <SideSection
            onClick={openSideMenuTab}
            onKeyPress={openSideMenuTab}
            tabIndex={0}
          >
            <SectionText>Contact Us</SectionText>
          </SideSection>
          {openContactUsTab && (
            <>
              <SideSectionHours role="menu">
                <SectionText>Contact Hours</SectionText>
                <SideNavContactDayContent
                  role="menuitem"
                  aria-label="monday to friday"
                >
                  Mon - Fri
                </SideNavContactDayContent>
                <SideNavContactHoursContent
                  role="menuitem"
                  aria-label="8am to 5pm CT"
                >
                  8:00 am - 5:00 pm CT
                </SideNavContactHoursContent>
                <SideEmailText>Email</SideEmailText>
                <SideEmailLink href="mailto:credit.line@ally.com">
                  Credit.Line@ally.com
                </SideEmailLink>
              </SideSectionHours>
            </>
          )}
        </SideNav>
      </div>
    </FocusOn>
  );
};

export default SideNavComponent;
