import { useAccordion } from "@ally/metronome-ui";
import { DocumentCategory, DocumentCategoryAriaLabels } from "../../Model/Constants/DocumentCategory";
import {
  AccordionContentStyle,
  RequiredFileUploadParagraphStyle,
} from "../RequiredDocAccordionStyled";
import { UploadFileComponent } from "../UploadFileComponent";

const CurrentYearFinancialStatements: React.FC = () => {
  const { handleFocus } = useAccordion();

  const currentYearFinancialMainContent =
    "Upload the most recent interim year-to-date financial statements, including income statement and balance sheet. CPA-audited or CPA-prepared statements are preferred; otherwise, provide company-prepared statements for each applicant.";

  return (
    <>
      <AccordionContentStyle>
        <RequiredFileUploadParagraphStyle ref={handleFocus} tabIndex={-1}>
          {currentYearFinancialMainContent}
        </RequiredFileUploadParagraphStyle>
      </AccordionContentStyle>
      <UploadFileComponent
        from={DocumentCategory.CurrentYearFinancialStatement}
        arialLabel={DocumentCategoryAriaLabels.CurrentYearFinancialStatementAriaLabel}
      />
    </>
  );
};

export default CurrentYearFinancialStatements;
