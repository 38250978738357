import React from "react";
import {
  StyledAccordionComponent,
  StyledAccordionWrapper,
  ResourceStyle,
} from "./ResourcedAccordionStyled";
import CreditLineApplicationForm from "./ResourceAccordionItems/CreditLineApplicationForm";
import OtherHelpfulInformation from "./ResourceAccordionItems/OtherHelpfulInformation";
import DocumentsYouMayNeed from "./ResourceAccordionItems/DocumentsYouMayNeed";

const Accordion: React.FC = () => {
  
  return (
    <>
      <StyledAccordionWrapper mode="FAQ">
        <ResourceStyle>Resources</ResourceStyle>
        <StyledAccordionComponent
          key="1"
          title="Credit Line Application form"
          content={{
            mode: "FAQ",
            payload: <CreditLineApplicationForm />
          }}
        />
        <StyledAccordionComponent
          key="2"
          title="Documents the Applicant May Need"
          content={{
            mode: "FAQ",
            payload: <DocumentsYouMayNeed />
          }}
        />
        <StyledAccordionComponent
          key="3"
          title="Other helpful information"
          content={{
            mode: "FAQ",
            payload: <OtherHelpfulInformation />
          }}
        />
      </StyledAccordionWrapper>
    </>
  );
};

export default Accordion;
