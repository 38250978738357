import { useAccordion } from "@ally/metronome-ui";
import {
  AlternativeDocumentsEmailInfoStyle,
  AlternativeDocumentsInfoStyle,
} from "../NeedHelpAccordionStyled";

const AlternativeDocuments: React.FC = () => {
  const { handleFocus } = useAccordion();

  return (
    <>
      <AlternativeDocumentsInfoStyle
        ref={handleFocus}
        tabIndex={-1}
        className="main_content"
      >
        If you're unable to upload the documents, you can email them to{" "}
        <AlternativeDocumentsEmailInfoStyle href="mailto:credit.line@ally.com">
          Credit.Line@ally.com
        </AlternativeDocumentsEmailInfoStyle>
        .
      </AlternativeDocumentsInfoStyle>
    </>
  );
};

export default AlternativeDocuments;
