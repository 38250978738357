import React, { useEffect, useState } from "react";
import {
  ContactStyledAccordionComponent,
  ContactStyledAccordionWrapper,
  ContactNameStyle,
  ContactEmailStyle,
  ContactPhoneNumberStyle,
} from "./ContactDetailsAccordionStyled";
import { ContactDetailProps } from "../Model/ContactDetailProps";
import { useAccordion } from "@ally/metronome-ui";

export const ContactDetail: React.FC<ContactDetailProps> = (
  props: ContactDetailProps
) => {
  const { handleFocus } = useAccordion();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <div
      data-private
      style={{ display: open ? "block" : "none" }}
    >
      <ContactNameStyle
        ref={handleFocus}
        tabIndex={-1}
        id="contactDetails_name"
      >
        {props.name} &nbsp;
      </ContactNameStyle>
      <ContactEmailStyle
        id="contactDetails_email"
      >
        {props.email} &nbsp;
      </ContactEmailStyle>
      <ContactPhoneNumberStyle
        id="contactDetails_phoneNumber"
      >
        {props.phoneNumber} &nbsp;
      </ContactPhoneNumberStyle>
    </div>
  );
};

export const ContactDetailsAccordion: React.FC<ContactDetailProps> = (
  props: ContactDetailProps
) => {
    const [open, setOpen] = useState(false);
    const toggleOpen = () => { setOpen(!open) };

  return (
    <ContactStyledAccordionWrapper mode="FAQ">
      <ContactStyledAccordionComponent
        onClick={toggleOpen}
        aria-level="2"
        key="1"
        title="Applicant contact details"
        content={{
          mode: "FAQ",
          payload: (
            <ContactDetail
              isOpen={open}
              name={props.name}
              email={props.email}
              phoneNumber={props.phoneNumber}
            />
          ),
        }}
      />
    </ContactStyledAccordionWrapper>
  );
};
