import {
  AccordionComponent,
  AccordionWrapper,
  Button,
  MuiIcon,
  TextBody,
} from "@ally/metronome-ui";
import styled from "styled-components";

export const RequiredDocAccordionComponent = styled(AccordionComponent)`
  div[data-accordion-component="AccordionItemPanel"] {
    font-weight: normal;
    line-height: 24px;
    font-size: 14px;
    color: #2a2a2a;
    padding: 0;
    margin: 0;
  }
`;

export const RequiredDocAccordionWrapper = styled(AccordionWrapper)`
  border: 1px solid #959595;
  margin-top: 26px;
  width: 564px;
  box-sizing: border-box;
  box-shadow: 0 4px 0 0 rgba(0,0,0,0.15);
  background-color: #F8F8F8;

  @media (max-width: 767px) {
    margin-top: 0px;
    width: 95%;
    padding-top: 5px;
    margin-right: 10px;    
  }

  div[data-accordion-component="AccordionItemHeading"] {

    div[data-accordion-component="AccordionItemButton"] > div > div > div > svg {
      margin-left: 8px;
    }
    
    div[data-accordion-component="AccordionItemButton"] > div > div > div > p {
      font-size: 14px;

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    div[data-accordion-component="AccordionItemButton"] > div > div > div > svg {
      margin-left: 8px;
    }
    


`;

export const AccordionContentStyle = styled.div`
  padding: 16px;
`;

export const RequiredFileUploadParagraphStyle = styled.p`
  font-size: 14px;
  line-height: 21px;
  font-weight: normal;
  margin-bottom: 8px;
  outline: none;
`;

export const RequiredFileUploadListStyle = styled.li`
  font-size: 14px;
  line-height: 21px;
  margin: 0 8px;
  padding-bottom: 4px;
  padding-left: 4px;
`;

export const RequiredFileUploadHeading = styled.h3`
  margin-top: 12px;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 24px;
  color: #2a2a2a;
`;

export const RequiredFileUploadUnorderedListStyle = styled.ul`
  padding-left: 30px;
  margin-bottom: 8px;
`;

export const StyledIcon = styled(MuiIcon)`
  padding-right: 4px;
  height: 20px;
  width: 20px;
  padding-top: 4px;
  font-weight: bold;
`;

export const OverrideTextBody = styled(TextBody)`
  margin-top: 0px;
  display: flex;
  padding-top: 5px;
  text-decoration: none;
`;

export const UploadImg = styled.img`
  align-items: center;
  height: 32px;
  width: 118px;
  margin-top: 16px;
`;

export const UploadButton = styled(Button)`
  box-sizing: border-box;
  height: 32px;
  width: 118px;
  border: 1.5px solid #dddddd;
  border-radius: 16px;
  background-color: #ffffff;
  div {
    color: #0071c4;
  }
`;

export const ErrorNotificationStyle = styled.div`
  line-height: 1.5;
  color: #D42825;
  font: weight: 14px;
  font-weight: bold;
  width: 100%;
  overflow-wrap: anywhere;
  word-break: break-all;
  margin-bottom: 12px;
`;

export const SuccessfullyUploadDivStyle = styled.div`
  display: inline-block;
  box-sizing: border-box;
  border-radius: 16px;
  padding-left: 12px;
  padding-right: 12px;
  overflow-wrap: anywhere;
  word-break: break-all;
  margin-bottom: 14px;
  background-color: #e7f6fd;

  &:focus {
    outline: none;
  }
`;
export const UnSuccessfullyUploadDivStyle = styled.div`
  display: inline-block;
  box-sizing: border-box;
  overflow-wrap: anywhere;
  color: #2a2a2a;
  font-size: 14px;
  font-weight: bold;
`;
export const CanceledStyledIcon = styled(MuiIcon)`
  margin-left: 4px;
  margin-bottom: 4px;
  align-self: center;
`;

export const LabelStyle = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 4px;
  margin-bottom: 4px;
  overflow-wrap: anywhere;
  word-break: break-all;
  width: 100%;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-item: center;
`;

export const LabelSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-item: center;
`;

export const ButtonContainer = styled.div`
  margin-top: 0px;
  margin-bottom: 24px;
  width: 93px;
  height: 34px;

  @media (max-width: 639px) {
    width: 100%;
  }
`;

export const ButtonStyle = styled(Button)`
  span:nth-child(2) > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const FileNameContainer = styled.div``;

export const UploadContainer = styled.div`
  padding: 0 16px 0 16px;
`;

export const SubmittedDocDiv = styled.div`
  background-color: #f8f8f8;
  padding: 20px 16px 20px 16px;
  overflow-wrap: anywhere;
  word-break: break-all;
`;

export const ChildUploadContainer = styled.li`
  list-style: none;
  &:focus {
    outline: none;
  }
`;

export const SubmittedFilesContainer = styled.div`
  margin-right: 2px;
`;

export const SubmittedText = styled.h3`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  color: #2a2a2a;
  font-weight: bold;
`;

export const SubmittedFileStyle = styled.p`
  font-size: 14px;
  line-height: 21px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 2px;
`;

export const UploadPlusIcon = styled(MuiIcon)`
  margin-right: 8px;
  height: 17px;
`;
