import React, { useEffect, useState } from "react";
import AllyCommercialService from "./RequiredAccordionItems/AllyCommercialService";
import CurrentYearFinancialStatements from "./RequiredAccordionItems/CurrentYearFinancialStatements";
import {
  RequiredDocAccordionWrapper,
  RequiredDocAccordionComponent,
} from "./RequiredDocAccordionStyled";
import GuarantorInformation from "./RequiredAccordionItems/GuarantorInformation";
import TwoPreviousYearEndStatement from "./RequiredAccordionItems/TwoPreviousYearEndStatement";
import { DocumentCategory } from "../Model/Constants/DocumentCategory";
import AdditionalStatementAccordion from "./SupportingDocumentsItems/AdditionalStatementAccordion";
import BankStatementAccordion from "./SupportingDocumentsItems/BankStatementAccordion";
import CollateralDocAccordion from "./SupportingDocumentsItems/CollateralDocAccordion";

const SubmittedDocAccordion: React.FC<any> = (props: any) => {
  const [categoryUploadedStatuses, setCategoryUploadedStatuses] = useState(
    props.uploadStatus
  );
  
  const someCategoriesUploaded = (): boolean => {
    return Object.values(categoryUploadedStatuses).some((status) => status);
  }

  useEffect(() => {
    setCategoryUploadedStatuses(props.uploadStatus);
  }, [props.allUploaded, props.uploadStatus]);

  return (
    <>
      {categoryUploadedStatuses && someCategoriesUploaded() && (
        <RequiredDocAccordionWrapper mode="CONTAINER">
          {categoryUploadedStatuses &&
            categoryUploadedStatuses[
              DocumentCategory.CreditLineApplication
            ] && (
              <RequiredDocAccordionComponent
                key="5"
                title="Ally Commercial Services Credit Line Application"
                content={{
                  mode: "CONTAINER",
                  payload: <AllyCommercialService />,
                }}
              />
            )}
          {categoryUploadedStatuses &&
            categoryUploadedStatuses[
              DocumentCategory.CurrentYearFinancialStatement
            ] && (
              <RequiredDocAccordionComponent
                key="6"
                title="Current Year Financial Statements"
                content={{
                  mode: "CONTAINER",
                  payload: <CurrentYearFinancialStatements />,
                }}
              />
            )}
          {categoryUploadedStatuses &&
            categoryUploadedStatuses[
              DocumentCategory.PreviousYearEndFinancialStatement
            ] && (
              <RequiredDocAccordionComponent
                key="7"
                title="Two Previous Year-End Financial Statements"
                content={{
                  mode: "CONTAINER",
                  payload: <TwoPreviousYearEndStatement />,
                }}
              />
            )}
          {categoryUploadedStatuses &&
            categoryUploadedStatuses[DocumentCategory.GuarantorInformation] && (
              <RequiredDocAccordionComponent
                key="8"
                title="Guarantor Information"
                content={{
                  mode: "CONTAINER",
                  payload: <GuarantorInformation />,
                }}
              />
            )}
          {categoryUploadedStatuses &&
            categoryUploadedStatuses[DocumentCategory.BankStatement] && (
              <RequiredDocAccordionComponent
                key="1"
                title="Bank Statements"
                content={{
                  mode: "CONTAINER",
                  payload: <BankStatementAccordion />,
                }}
              />
            )}
          {categoryUploadedStatuses &&
            categoryUploadedStatuses[
              DocumentCategory.CrossDefaultCrossCollateralizationAgreement
            ] && (
              <RequiredDocAccordionComponent
                key="2"
                title="Cross Default / Cross Collateralization Agreement"
                content={{
                  mode: "CONTAINER",
                  payload: <CollateralDocAccordion />,
                }}
              />
            )}
          {categoryUploadedStatuses &&
            categoryUploadedStatuses[DocumentCategory.AdditionalDocument] && (
              <RequiredDocAccordionComponent
                key="3"
                title="Additional Documents"
                content={{
                  mode: "CONTAINER",
                  payload: <AdditionalStatementAccordion />,
                }}
              />
            )}
        </RequiredDocAccordionWrapper>
      )}
    </>
  );
};

export default SubmittedDocAccordion;
