import styled from "styled-components";
import { Box, Button, TextInput } from "@ally/metronome-ui";
import { MaskedInput } from "react-hook-mask";

export const AlertStyle = styled.div`
  display: flex;
  margin-left: 43px;
  margin-right: 43px;
  margin-top: 24px;

  @media (max-width: 767px) {
    padding-top: 10px;
    margin-left: 20px;
    margin-bottom: 24px;
  }
`;

export const ButtonContainer = styled.div`
  padding-top: 20px;
`;

export const ButtonStyle = styled(Button)`
  span:nth-child(2) > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 767px) {
    width: 90px;
  }

  @media (max-width: 639px) {
    width: 87vw;
  }
`;

export const LabelStyle = styled.div`
  font-size: 11px;
  line-height: 19.8px;
  font-weight: bold;
  padding-top: 12px;
  color: #000000;
`;

export const Heading = styled.h1`
  display: flex;
  max-width: 564px;
  margin-right: 24px;
  padding-top: 30px;
  margin-left: 43px;

  font-weight: bold;
  line-height: 54px;
  font-size: 36px;

  @media (max-width: 767px) {
    width: 100%;
    font-size: 24px;
    line-height: 36px;
    margin-left: 20px;
    padding-top: 0;
    color: black;
  }

  @media (max-width: 639px) {
    font-size: 24px;
    width: 90vw;
    line-height: 36px;
    margin-left: 20px;
    padding-top: 0;
    color: black;
  }

  @media (max-width: 300px) {
    width: 90vw;
  }
`;

export const SubHeader = styled.p`
  padding-top: 10px;
  margin-left: 43px;
  font-weight: normal;
  max-width: 564px;
  line-height: 30px;
  font-size: 20px;
  color: #2a2a2a;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
    width: 80%;
    margin-left: 20px;
  }

  @media (max-width: 639px) {
    font-size: 16px;
    width: 90vw;
    line-height: 24px;
    margin-left: 20px;
    padding-top: 24px;
  }

  @media (max-width: 300px) {
    width: 90vw;
  }
`;

export const SideBySide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 120px;

  @media (min-width: 767px) and (min-height: 800px) {
    margin-bottom: calc(100vh - 790px);
  }

  @media (max-width: 848px) and (min-height: 800px) {
    margin-bottom: calc(100vh - 815px);
  }

  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 0;
  }

  @media (min-width: 767px) and (min-height: 1000px) {
    // margin-bottom: calc(100vh - 815px);
  }

  @media (min-width: 1330px) {
    padding-top: 0;
  }
`;

export const AlignColumn = styled.div`
  flex-direction: column;

  @media (max-width: 767px) {
    flex-direction: row;
    justify-content: left;
    align-item: left;
  }

  @media (max-width: 500px) {
    margin-right: 20px;
  }
`;

export const AccordionStyle = styled.div`
  width: 349px;
  height: 144.5px;

  box-sizing: border-box;
  padding-bottom: 10px;
  margin-right: 44px;

  @media (max-width: 767px) {
    display: flex;
    position: relative;
    width: 80vw;
    height: 80%;
    align-self: left;
    padding-bottom: 2vh;
  }
  @media (max-width: 639px) {
    width: 93vw;
  }

  @media (max-width: 500px) {
    width: 91vw;
  }

  @media (max-width: 350px) {
    width: 93vw;
  }
`;

export const FormContainer = styled.div``;

export const TextInputStyle = styled(TextInput)`
  @media (max-width: 639px) {
    width: 87vw;
  }
`;

export const BoxStyle = styled(Box)`
  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

export const FixedDivStyled = styled.div`
  position: ${(props) => props.itemProp};
  width: 100%;
  @media (min-width: 1330px) {
    width: 1330px;
    margin: 0 auto;
  }
`;

export const ModalLayoutDivStyle = styled.div`
  position: ${(props) => props.itemProp};
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.itemProp === "fixed" ? "rgba(0,0,0,0.3)" : "inherit"};
  z-index: 2;
`;

export const BodyDivStyle = styled.div`
  position: relative;

  @media (max-width: 1330px) and (min-height: 760px) and (max-height: 980px) {
    overflow: hidden;
  }

  @media (min-width: 1330px) and (min-height: 760px) and (max-height: 980px) {
    overflow: hidden;
  }
`;

export const MaskInputStyle = styled(MaskedInput)`
  width: 298px;
  height: 34px;
  color: #2a2a2a;
  border: 1px solid #959595;
  box-sizing: border-box;
  padding: 4px 0px 4px 8px;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  font-size: 14px;
  font-weight: 400;

  @media (max-width: 767px) {
    height: 45px;
  }
  @media (max-width: 639px) {
    width: 87vw;
  }
`;

export const PhoneErrorDiv = styled.div`
  color: rgb(212, 40, 37);
  font-size: 14px;
  font-weight: 700;
`;
