import LogRocket from "logrocket";

const logrocketEnv = process.env.REACT_APP_LOGROCKET;
LogRocket.init(logrocketEnv, {
  sdkServer: 'https://cdn.lr-hv-in.com',
  ingestServer: 'https://r.lr-hv-in.com',
  network: {
    requestSanitizer: request => {
      // remove all query parameters from requests to documents/exchange
      if (request.url.toLocaleLowerCase().indexOf('documents/exchange') !== -1) {
        request.url = request.url.substring(0, request.url.indexOf('?'));
      }

      // remove all query parameters from uploads to S3
      if (request.method === 'PUT' && request.url.toLocaleLowerCase().indexOf('s3.amazonaws.com') !== -1) {
        request.url = request.url.substring(0, request.url.indexOf('?'));
      }

      return request;
    },

    responseSanitizer: response => {
      const body = JSON.parse(response.body);
      
      // sanitize upload-status
      if (body.hasOwnProperty('phoneNumber') && body.hasOwnProperty('email')) {
        delete body.phoneNumber;
        delete body.email;
        response.body = JSON.stringify(body);
      }

      // sanitize upload-url
      if (body.hasOwnProperty('url')) {
        body.url = null;
        response.body = JSON.stringify(body);
      }

      return response;
    }
  }
});
