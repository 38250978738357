import { muiDownload } from "@ally/metronome-icons/dist/cjs";
import { useAccordion } from "@ally/metronome-ui";
import { DocumentCategory, DocumentCategoryAriaLabels } from "../../Model/Constants/DocumentCategory";
import {
  AccordionContentStyle,
  RequiredFileUploadParagraphStyle,
} from "../RequiredDocAccordionStyled";
import {
  StyledIcon,
  PdfDownloadLink,
  PdfLabel,
} from "../ResourcedAccordionStyled";
import { UploadFileComponent } from "../UploadFileComponent";

const GuarantorInformation: React.FC = () => {
  const { handleFocus } = useAccordion();

  const mainContent =
    "Each applicant has the option to provide a personal guarantee from the business owners. A personal guarantee may also be needed to enable the extension of a credit line. If an applicant is providing a personal guarantee, upload a completed Individual Authorization and Personal Financial Statement form for each guarantor.";

  return (
    <>
      <AccordionContentStyle>
        <RequiredFileUploadParagraphStyle ref={handleFocus} tabIndex={-1}>
          {mainContent}
        </RequiredFileUploadParagraphStyle>
        <StyledIcon muiIcon={muiDownload} fill="#0071C4" ariaHidden />
        <PdfDownloadLink
          download
          href="https://www.ally.com/content/dam/pdf/dealer/online/cl-auth-individual-form.pdf"
          style={{ textDecoration: "none" }}
          rel="noreferrer noopener"
          target="_blank"
          aria-label="Download Individual Authorization (PDF)"
        >
          Download Individual Authorization
        </PdfDownloadLink>
        <PdfLabel>&nbsp;(PDF)</PdfLabel>
        <br />
        <StyledIcon muiIcon={muiDownload} fill="#0071C4" ariaHidden />
        <PdfDownloadLink
          download
          href="https://www.ally.com/content/dam/pdf/dealer/online/personal-financial-statement-form.pdf"
          style={{ textDecoration: "none" }}
          rel="noreferrer noopener"
          target="_blank"
          aria-label="Download Personal Financial Statement (PDF)"
        >
          Download Personal Financial Statement
        </PdfDownloadLink>
        <PdfLabel>&nbsp;(PDF)</PdfLabel>
      </AccordionContentStyle>
      <UploadFileComponent
        from={DocumentCategory.GuarantorInformation}
        arialLabel={DocumentCategoryAriaLabels.GuarantorInformationAriaLabel}
      />
    </>
  );
};

export default GuarantorInformation;
