import { useAccordion } from "@ally/metronome-ui";
import { DocumentCategory, DocumentCategoryAriaLabels } from "../../Model/Constants/DocumentCategory";
import { AccordionContentStyle } from "../RequiredDocAccordionStyled";
import { UploadFileComponent } from "../UploadFileComponent";
import {
  AdditionalFileUploadParagraphStyle,
  AdditionalFileUploadUnorderedListStyle,
  AdditionalFileUploadListStyle,
} from "./AdditionalStatementAccordionStyled";

const AdditionalStatementAccordion = () => {
  const { handleFocus } = useAccordion();

  const collateralContent = `Additional documents may 
    be required to complete the credit review and/or activate the credit line. Examples include:`;

  const additionalBulletOne = `Proof of Taxpayer Identification Number (IRS Form SS-4)`;

  const additionalBulletTwo = `Proof of authorized signer (of the applicant)`;

  const additionalBulletThree = `Corporate resolution document`;

  return (
    <>
      <AccordionContentStyle>
        <AdditionalFileUploadParagraphStyle
          ref={handleFocus}
          tabIndex={-1}
        >
          {collateralContent}
        </AdditionalFileUploadParagraphStyle>
        <AdditionalFileUploadUnorderedListStyle>
          <AdditionalFileUploadListStyle>
            {additionalBulletOne}
          </AdditionalFileUploadListStyle>
          <AdditionalFileUploadListStyle>
            {additionalBulletTwo}
          </AdditionalFileUploadListStyle>
          <AdditionalFileUploadListStyle>
            {additionalBulletThree}
          </AdditionalFileUploadListStyle>
        </AdditionalFileUploadUnorderedListStyle>
      </AccordionContentStyle>
      <UploadFileComponent
        from={DocumentCategory.AdditionalDocument}
        arialLabel={DocumentCategoryAriaLabels.AdditionalDocumentAriaLabel}
      />
    </>
  );
};

export default AdditionalStatementAccordion;
