//this singleton keeps track of each document type's submission-pending state
export class DocSubmitTrackerService {
  
  // Singleton Design Pattern
  static instance: DocSubmitTrackerService;
  
  // Array to keep track of submission-pending status of each doc type
  private docSubmittedTracker: { [index: string]: boolean } = {};

  public static getInstance(): DocSubmitTrackerService {
    if (!DocSubmitTrackerService.instance) {
      DocSubmitTrackerService.instance = new DocSubmitTrackerService();
    }

    return DocSubmitTrackerService.instance;
  }

  public setCategoryHasPendingFiles(docCategory : string, filesArePending: boolean): void
  {
    this.docSubmittedTracker[docCategory] = filesArePending;
  }

  public anySubmitPending(): boolean
  {
    const arrayOfValues = Object.values(this.docSubmittedTracker);
    
    return arrayOfValues.some(value => value === true);
  }
}
