import React from "react";
import { FooterContainer } from "../../AppStyled";
import { FixedDivStyled, ModalLayoutDivStyle } from "../Form/FormStyles";
import { childProps } from "../Model/ChildProps";
import {
  Container,
  FooterNav,
  FooterNavItem,
  Copyright,
  FooterNavLink,
} from "./FooterStyled";

const Footer: React.FC<childProps> = (props: childProps) => {
  return (
    <>
      <FixedDivStyled itemProp={props.modal === "opened" ? "fixed" : "static"}>
      <ModalLayoutDivStyle
              itemProp={props.modal === "opened" ? "fixed" : "static"}
            ></ModalLayoutDivStyle>
        <Container role="contentinfo">
          <FooterNav>
            <FooterNavItem>
              <FooterNavLink
                href="https://www.ally.com/about/"
                id="aboutUsLink"
                target="_blank"
                rel="noreferrer noopener"
              >
                About Us
              </FooterNavLink>
            </FooterNavItem>
            <FooterNavItem>
              <FooterNavLink
                href="https://www.ally.com/privacy/dealer/"
                id="privacyLink"
                target="_blank"
                rel="noreferrer noopener"
              >
                Privacy
              </FooterNavLink>
            </FooterNavItem>
            <FooterNavItem>
              <FooterNavLink
                href="https://www.ally.com/security/dealer/"
                id="securityLink"
                target="_blank"
                rel="noreferrer noopener"
              >
                Security
              </FooterNavLink>
            </FooterNavItem>
            <FooterNavItem>
              <FooterNavLink
                href="https://www.ally.com/legal/dealer/"
                id="legalLink"
                target="_blank"
                rel="noreferrer noopener"
              >
                Legal
              </FooterNavLink>
            </FooterNavItem>
          </FooterNav>
          <Copyright>
            ©2009-{new Date().getFullYear()} Ally Financial Inc.
          </Copyright>
        </Container>
      </FixedDivStyled>
      <FooterContainer></FooterContainer>
    </>
  );
};

export default Footer;
