import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Logo,
  Menu,
  MenuItem,
  LogoImg,
  LogoLink,
  MenuLink,
  StyledIcon,
  StyledDropdownContainer,
  ContactStyle,
  MobileMenu,
  MobileMenuText,
  SubMenuLink,
} from "./NavigationStyled";
import logo from "../../assets/ally_white.png";
import { DropdownMenu } from "@ally/metronome-ui";
import { muiChevronDown } from "@ally/metronome-icons";
import ContactUs from "./ContactUs";
import { CgMenu } from "react-icons/cg";
import SideNav from "./SideNavigation";

interface navigationChildProps {
  updateModal: (arg: string) => void;
}
const Navigation: React.FC<navigationChildProps> = (
  props: navigationChildProps
) => {
  const [open, setOpen] = useState(false);
  const hamburgerRef = useRef(null);

  useEffect(() => {
    hamburgerRef.current?.focus();
  });

  const hamburgerIcon = (
    <CgMenu
      size="30px"
      color="white"
      onClick={() => {
        props.updateModal("opened");
        setOpen(!open);
      }}
    />
  );

  const updateStateInNavigation = (arg: string) => {
    if (arg === "closed") {
      props.updateModal(arg);
      setOpen(false);
    }
  };


  const onContactUsMenuClick = (isOpen: boolean) => {
    const alias = 'contactUsMenu:' + (isOpen ? "opened" : "closed");
    window.allytm.event('clickEvent', { tagName: 'SPAN', allytmln: alias, href: 'javascript:void(0);' });
  }
  const mobileMenuClick = (isOpen: boolean) => {
    const alias = 'mobileMenu:' + (isOpen ? "opened" : "closed");
    window.allytm.event('clickEvent', { tagName: 'DIV', allytmln: alias, href: 'javascript:void(0);' });
  }
  return (
    <>
      <Container id={open ? 'hamburger-opened' : 'hamburger-closed'}>
        {!open && (
          <MobileMenu
            ref={hamburgerRef}
            data-test-id="hamburger-menu"
            tabIndex={0}
            onKeyPress={() => {
              props.updateModal("opened");
              setOpen(!open);
            }}
            onClick={() => {
              mobileMenuClick(true);
              props.updateModal("opened");
              setOpen(!open);
            }}
          >
            {hamburgerIcon}
            <MobileMenuText>MENU</MobileMenuText>
          </MobileMenu>
        )}
        {open && (
          <div>
            <MobileMenu 
              data-test-id="mobile-close-icon" 
              onClick={ () => {
                mobileMenuClick(false);
              }}
            >
              <SideNav updateState={(arg) => updateStateInNavigation(arg)} />
            </MobileMenu>
          </div>
        )}

        {!open && (
          <Logo>
            <LogoLink
              href="http://www.ally.com"
              id="ally-logo"
              rel="noreferrer noopener"
            >
              <LogoImg src={logo} alt="ally logo go to ally.com" />
            </LogoLink>
          </Logo>
        )}

        <Menu>
          <MenuItem>
            <MenuLink role="group" tabIndex={-1} aria-label="Contact Us Menu">
              <DropdownMenu caretOffsetX={30} topPos={35} onMenuToggle={onContactUsMenuClick}>
                <DropdownMenu.Trigger
                  
                  id="menu trigger"
                  ariaLabel={(isOpen): string =>
                    isOpen ? "Close Contact Us Menu" : "Open Contact Us Menu"
                  }
                  content={
                    <SubMenuLink id="menu link">
                      Contact Us
                      <StyledIcon
                        id="menu icon"
                        muiIcon={muiChevronDown}
                        size="md"
                        fill="white"
                        ariaHidden
                      />
                    </SubMenuLink>
                  }
                />
                <StyledDropdownContainer>
                  <ContactStyle id="submenu">{<ContactUs />}</ContactStyle>
                </StyledDropdownContainer>
              </DropdownMenu>
            </MenuLink>
          </MenuItem>
        </Menu>
      </Container>
    </>
  );
};

export default Navigation;
