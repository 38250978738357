import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ContactDetailsAccordion } from "../Accordion/ContactDetailsAccordion";
import NeedHelpAccordion from "../Accordion/NeedHelpAccordion";
import DocumentTabs from "./DocumentTabs";
import {
  AlertStyle,
  ApplicationHeaderStyle,
  ApplicationInfoStyle,
  ExitButton,
  ExitContainerStyle,
  ExitInfoStyle,
  MiddleContainerStyle,
  UploadDivStyle,
} from "./UploadDocumentStyled";
import { Alert, Modal } from "@ally/metronome-ui";
import { AlertContext } from "../../context/alertContext";
import DocDocService from "../../services/docDoc/docDocService";
import { CategoryUploadStatusList } from "../Model/CategoryUploadStatusList";
import { DocumentCategory } from "../Model/Constants/DocumentCategory";
import MobileNeedHelpAccordion from "../Accordion/MobileNeedHelpAccordion";
import { DocSubmitTrackerService } from "../../services/docSubmitTrackerService";
import ExitModal from "./ExitModal";
import { useLDClient } from "launchdarkly-react-client-sdk";

type TriggerProps = {
  openModal?: () => void;
  text?: string;
};

export const ModalTrigger: React.FC<TriggerProps> = ({
  openModal,
  text = "Exit",
}) => {
  const exitRedirect = () => {
    let docSubmitTracker = DocSubmitTrackerService.getInstance();
    if (docSubmitTracker.anySubmitPending()) {
      openModal();
    } else {
      const exitLink = "https://www.ally.com/dealer/";
      window.location.href = exitLink;
    }
  };

  return <ExitButton allytmln="ExitBtn" variant="primary" content={text} onClick={exitRedirect} />;
};

const UploadDoc: React.FC = (mb) => {
  const spanStyle: CSSProperties = {
    color: "#2a2a2a",
    fontFamily: "Lato",
    fontWeight: 600,
  };

  const location = useLocation();
  const [alert, setAlert] = useState(false);
  const headingRef = useRef<HTMLHeadingElement>(null);
  const errorRef = React.useRef(null);

  let blankCategoryStatus: CategoryUploadStatusList = {};
  for (const category in Object.values(DocumentCategory)) {
    blankCategoryStatus[category] = false;
  }

  const initialCategoryStatus = location.state.uploadStatus
    ? location.state.uploadStatus
    : blankCategoryStatus;

  const [uploadStatus, setUploadStatus] = useState(initialCategoryStatus);
  const [isLoading, setIsLoading] = useState(false);
  const docDocService = DocDocService.getInstance();
  const nonBreakingHyphenCompanyName = (location.state.userInfo.CompanyName as string)
    .replace('-', '\u2011');
  const ldClient = useLDClient();
  const [maintenanceAlert, setMaintenanceAlert] = useState(false);
  

  useEffect(() => {
    window.allytm.event("pageView", "CSG:Credit Line Application:Upload Documents");
    headingRef.current?.focus();
  }, []);

  useEffect(() => {
    setMaintenanceAlert(ldClient.variation('maintenance-mode', false));
    const doNotReloadUploadStatus =
      localStorage.getItem("doNotRefreshUploadStatus") === "true";

    if (doNotReloadUploadStatus && location.state?.uploadStatus) {
      setUploadStatus(location.state.uploadStatus);

      // Once we receive upload status initially, we should now refresh upload status
      localStorage.setItem("doNotRefreshUploadStatus", "false");
    } else {
      setIsLoading(true);
      docDocService
        .getStatus(
          location.state.userInfo.PhoneNumber,
          location.state.userInfo.Email
        )
        .then((result) => {
          setUploadStatus(result);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setAlert(true);
          errorRef.current?.focus();
        });
    }
  }, [setUploadStatus, setIsLoading, alert]);

  return (
    <UploadDivStyle>
      {maintenanceAlert && (
        <AlertStyle>
          <Alert
            variant="info"
            cancellable
            contained
            backgroundWhite
            ref={errorRef}
            handleClose={() => setMaintenanceAlert(false)}
            body="Maintenance Mode.  We are currently making updates to this application.  Please try again later."
          />
        </AlertStyle>
      )}
      {alert && (
        <AlertStyle>
          <Alert
            variant="error"
            id="error_alert"
            cancellable
            contained
            backgroundWhite
            ref={errorRef}
            handleClose={() => setAlert(false)}
            body="We can't complete your request right now. Try again later, or call us for help."
          />
        </AlertStyle>
      )}
      <ApplicationHeaderStyle ref={headingRef} tabIndex={-1}>
        Application for {nonBreakingHyphenCompanyName}
      </ApplicationHeaderStyle>
      <ContactDetailsAccordion
        isOpen={false}
        name={location.state.userInfo.ApplicantName}
        email={location.state.userInfo.Email}
        phoneNumber={location.state.userInfo.PhoneNumber}
      />
      <ApplicationInfoStyle>
        The applicant's documents can be submitted in a single session or
        provided in multiple sessions.
      </ApplicationInfoStyle>
      <MiddleContainerStyle>
        <AlertContext.Provider value={{ alert, setAlert }}>
          <DocumentTabs
            categoryUploadStatuses={uploadStatus}
            sectionsAreLoading={isLoading}
          />
        </AlertContext.Provider>
        <NeedHelpAccordion />
      </MiddleContainerStyle>
      <ExitContainerStyle>
        <ExitInfoStyle>
          To leave this page, select <span style={spanStyle}>Exit</span> or
          close your browser window.
        </ExitInfoStyle>
        <Modal
          trigger={<ModalTrigger />}
          content={<ExitModal />}
          fullScreenMd={false}
          disableFullScreenSmDown={true}
          width="sm"
        />
        <MobileNeedHelpAccordion />
      </ExitContainerStyle>
    </UploadDivStyle>
  );
};

export default UploadDoc;
