import { Button } from "@ally/metronome-ui";
import styled from "styled-components";

export const ExitModalContent = styled.div`
  display: flex;
  flex-direction: column;
  p { 
    font-size: 16px;
  }
`

export const ExitModalSection = styled.div`
  padding: 12px 0px 0px 0px;

`

export const ExitButtonSection = styled.div`
  padding: 24px 0px 0px 0px;
  display: flex;
  align-items: center;

  @media (max-width: 639px) {
    flex-direction: column;
  }
`

export const ExitLink = styled(Button)`
  padding-left: 12px;

  span {
    font-size: 16px;
  }

  @media (max-width: 639px) {
    padding: 12px 0 0 0;
  }
`
