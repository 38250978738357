import { Button } from "@ally/metronome-ui";
import styled from "styled-components";

export const UploadDivStyle = styled.main`
  margin-bottom: 95px;
  position: relative;
`;

export const AlertStyle = styled.div`
  display: flex;
  margin-left: 43px;
  margin-right: 43px;
  margin-top: 24px;

  @media (max-width: 767px) {
    margin-left: 24px;
    padding-top: 10px;
  }
`;

export const ApplicationHeaderStyle = styled.h1`
  display: inline-block;
  font-size: 36px;
  color: #2a2a2a;
  line-height: 54px;
  padding-bottom: 8px;
  margin-left: 43px;
  margin-top: 24px;
  word-wrap: break-word;
  width: 90%;
  outline: none;

  @media (max-width: 767px) {
    padding-top: 10px;
    margin-left: 20px;
    margin-top: 0;
    font-size: 30px;
    word-wrap: break-word;
  }
`;

export const ApplicationInfoStyle = styled.p`
  width: 580px;
  height: 21px;
  font-size: 14px;
  line-height: 21px;
  color: #2a2a2a;
  margin-top: 12px;
  margin-left: 43px;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    margin-left: 20px;
    width: 90vw;
  }
`;

export const MiddleContainerStyle = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: 1330px) {
    width: 1330px;
    position: relative;
  }
`;

export const ExitInfoStyle = styled.p`
  width: 580px;
  font-size: 14px;
  line-height: 21px;
  color: #2a2a2a;
  margin-top: 24px;
  margin-left: 43px;

  @media (max-width: 767px) {
    margin-left: 20px;
    width: 90vw;
  }
`;

export const ExitButton = styled(Button)`
  box-sizing: border-box;
  margin-left: 42px;
  margin-top: 8px;
  @media (max-width: 767px) {
    margin-left: 20px;
    width: 90px;
  }

  @media (max-width: 639px) {
    width: 90vw;
  }
`;
export const ExitContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
`;
