import styled from "styled-components";

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  height: 100px;
  color: blue;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #dfdfdf;
  background-color: #f8f8f8;
  position: relative;
  bottom: 0;
  @media (max-width: 639px) {
    height: 195px;
  }

`;

export const Copyright = styled.div`
  margin-left: 33px;
  margin-right: 33px;
  font-size: 14px;
  line-height: 19px;
  height: 38px;
  color: #2a2a2a;
  align-items: center;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const FooterNav = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 639px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterNavLink = styled.a`
  border: none;
  height: 3.75rem;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 12;
  line-height: 17px;
  justify-content: center;
  font-weight: bold;
  color: #0071c4;
  

  @media (max-width: 767px) {
    line-height: 24px;
    font-size: 16px;
  }
  @media (max-width: 500px) {
    line-height: 24px;
    font-size: 16px;
  }
`;

export const FooterNavItem = styled.div`
  margin-right: 30px;
  margin-top: 24.5px;
  margin-bottom: auto;
  padding: 2px;
  font-size: 14px;

  @media (max-width: 767px) {
    padding-top: 2px;
    margin-top: 0;

  }

  @media (max-width: 500px) {
    padding-top: 2px;
  }
`;
