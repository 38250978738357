import styled from "styled-components";

export const ContactUsContainer = styled.div`
  outline: none;
`;

export const ContactHoursText = styled.p`
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  padding-left: 20px;
  padding-top: 20.14px;
  padding-bottom: 10px;
  color: #2a2a2a;
`;
export const ContactHoursContent = styled.p`
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;
  padding-left: 20px;
  padding-top: 2px;
  color: #2a2a2a;
`;
export const EmailText = styled.p`
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  padding-left: 20px;
  padding-top: 18px;
  padding-bottom: 2px;
  color: #2a2a2a;
`;
export const EmailLink = styled.a`
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  padding-left: 20px;
  text-decoration: none;
  color: #0071c4;
`;
