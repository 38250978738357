import { SkeletonLoader, Tabs } from "@ally/metronome-ui";
import { AccountType } from "@ally/metronome-ui/dist/cjs/Tabs/Tabs";
import RequiredDocAccordion from "../Accordion/RequiredDocAccordion";
import SupportingDocumentAccordion from "../Accordion/SupportingDocumentAccordion";
import {
  SelectedDropdownTrigger,
  StyledBoxDropDown,
  StyledTab,
  StyledTextBody,
  StyledTextHeading,
  SubmittedStyledTextHeading,
  MobileDocumentTypeStyle,
  SubmittedSectionStyle,
  SkeletonLoaderStyle,
  SkeletonLoaderTopMarginStyle,
} from "./DocumentTabsStyled";
import SubmittedDocAccordion from "../Accordion/SubmittedDocAccordion";
import { DocumentTabProps } from "../Model/DocumentTabsProps";
import { DocumentCategory } from "../Model/Constants/DocumentCategory";
import { useEffect, useRef, useState } from "react";

const TabsExampleTwo: React.FC<DocumentTabProps> = (
  props: DocumentTabProps
) => {
  const itemsRef: HTMLHeadingElement[] = [];

  const [requiredCategoryUploadStatuses, setRequiredCategoryUploadStatuses] =
    useState([]);
  
  const allRequiredCategoriesUploaded = (): boolean => {
    const allStatuses = Object.values(
      requiredCategoryUploadStatuses
    ) as boolean[];
    return (
      allStatuses.length > 0 &&
      allStatuses.filter((status) => !status).length === 0
    );
  };

  const [
    supportingCategoryUploadStatuses,
    setSupportingCategoryUploadStatuses,
  ] = useState([]);

  const allSupportingCategoriesUploaded = (): boolean => {
    const allStatuses = Object.values(
      supportingCategoryUploadStatuses
    ) as boolean[];
    return (
      allStatuses.length > 0 &&
      allStatuses.filter((status) => !status).length === 0
    );
  };

  const [categoryUploadedStatuses, setCategoryUploadedStatuses] = useState(
    props.categoryUploadStatuses
  );
  const someCategoriesUploaded = (): boolean => {
    return Object.values(categoryUploadedStatuses).some((status) => status);
  };

  const [sectionsLoading, setSectionsLoading] = useState(false);
  const [document, setDocument] = useState("Required");
  const refValue = useRef(null);

  useEffect(() => {
    const requiredCategories = [
      DocumentCategory.CreditLineApplication,
      DocumentCategory.CurrentYearFinancialStatement,
      DocumentCategory.GuarantorInformation,
      DocumentCategory.PreviousYearEndFinancialStatement,
    ];

    const supportingCategories = [
      DocumentCategory.AdditionalDocument,
      DocumentCategory.BankStatement,
      DocumentCategory.CrossDefaultCrossCollateralizationAgreement,
    ];

    const requiredCategoryUploadStatuses = requiredCategories.map(
      (category: string) => props.categoryUploadStatuses[category]
    );

    setRequiredCategoryUploadStatuses(requiredCategoryUploadStatuses);

    const supportingCategoryUploadStatuses = supportingCategories.map(
      (category: string) => props.categoryUploadStatuses[category]
    );

    setSupportingCategoryUploadStatuses(supportingCategoryUploadStatuses);
    setCategoryUploadedStatuses(props.categoryUploadStatuses);
    setSectionsLoading(props.sectionsAreLoading);
  }, [props.categoryUploadStatuses, props.sectionsAreLoading]);

  const handleTabChange = (i: any) => {
    itemsRef[i].focus();
  };

  const handleRef = (el: HTMLHeadingElement | null): number | null => {
    return el && itemsRef.push(el);
  };

  const options = [
    { value: 0, label: "Required documents" },
    { value: 1, label: "Supporting documents" },
  ];

  const selectionChange = (
    e: React.SyntheticEvent<HTMLSelectElement>
  ): void => {
    const selection = parseInt(e.currentTarget.value, 10);
    if (selection === 1) {
      setDocument("Supporting");
    }
    if (selection === 0) {
      setDocument("Required");
    }
  };

  return (
    <>
      <Tabs.Wrapper
        onChange={handleTabChange}
        suppressMdDownLayout
      >
        <StyledTab
          account={AccountType.Checking}
          allytmln="RequiredDocsTab"
          tab="Required documents"
          panel={
            <>
              <StyledTextHeading
                tag="h2"
                size="sm"
                tabIndex={-1}
                data-test-id="required_documents_tab"
                ref={handleRef}
              >
                Required documents
              </StyledTextHeading>
              {!sectionsLoading && (
                <>
                  {!allRequiredCategoriesUploaded() && categoryUploadedStatuses && (
                  <StyledTextBody tag="p" size="sm">
                    These documents are required for all new applications.
                  </StyledTextBody>
                  )}
                  {allRequiredCategoriesUploaded() && (
                    <StyledTextBody tag="p" size="sm">
                      All required documents have been submitted. Select the document type under <strong>Submitted documents</strong> to make any changes.
                    </StyledTextBody>
                  )}
                  <RequiredDocAccordion
                    uploadStatus={categoryUploadedStatuses}
                    allUploaded={allRequiredCategoriesUploaded()}
                  />
                </>
              )}
              {sectionsLoading && (
                <SkeletonLoaderTopMarginStyle>
                  <SkeletonLoader.Container isLoading={sectionsLoading}>
                    <SkeletonLoader.Row height="xl" width="xl" />
                  </SkeletonLoader.Container>
                </SkeletonLoaderTopMarginStyle>
              )}
            </>
          }
        />
        <StyledTab
          account={AccountType.Savings}
          allytmln="SupportingDocsTab"
          tab="Supporting documents"
          panel={
            <>
              <StyledTextHeading
                tag="h2"
                size="md"
                tabIndex={-1}
                ref={handleRef}
              >
                Supporting documents (if requested)
              </StyledTextHeading>
              {!allSupportingCategoriesUploaded() && (
                <StyledTextBody tag="p" size="sm">
                  After reviewing the application, we may require additional
                  documents to support the application. If so, we'll contact the
                  applicant with specific instructions.
                </StyledTextBody>
              )}
              {allSupportingCategoriesUploaded() && (
                <StyledTextBody tag="p" size="sm">
                  All supporting documents have been submitted. Select the document 
                  type under <strong>Submitted documents</strong> to make any changes.
                </StyledTextBody>
              )}
              

              {!sectionsLoading && (
                <SupportingDocumentAccordion
                  uploadStatus={categoryUploadedStatuses}
                  allUploaded={allSupportingCategoriesUploaded()}
                />
              )}
              {sectionsLoading && (
                <SkeletonLoaderTopMarginStyle>
                  <SkeletonLoader.Container isLoading={sectionsLoading}>
                    <SkeletonLoader.Row height="xl" width="xl" />
                  </SkeletonLoader.Container>
                </SkeletonLoaderTopMarginStyle>
              )}
            </>
          }
        />
      </Tabs.Wrapper>
      <StyledBoxDropDown>
        <MobileDocumentTypeStyle>DOCUMENT TYPE</MobileDocumentTypeStyle>
        <SelectedDropdownTrigger
          aria-label="document-options"
          id="documents"
          options={options}
          ref={refValue}
          onChange={selectionChange}
        />

        <div
          style={{
            display: `${document === "Required" ? "block" : "none"}`,
          }}
        >
          <StyledTextHeading tag="h2" size="md" tabIndex={-1} ref={handleRef}>
            Required documents
          </StyledTextHeading>
          {!sectionsLoading && (
            <>
              {!allRequiredCategoriesUploaded() && categoryUploadedStatuses && (
              <StyledTextBody tag="p" size="sm">
                These documents are required for all new applications.
              </StyledTextBody>
              )}
              {allRequiredCategoriesUploaded() && (
                <StyledTextBody tag="p" size="sm">
                  All required documents have been submitted. Select the document type under <strong>Submitted documents</strong> to make any changes.
                </StyledTextBody>
              )}
              <RequiredDocAccordion
                uploadStatus={categoryUploadedStatuses}
                allUploaded={allRequiredCategoriesUploaded()}
              />
            </>
          )}
          {sectionsLoading && (
            <SkeletonLoaderTopMarginStyle>
              <SkeletonLoader.Container isLoading={sectionsLoading}>
                <SkeletonLoader.Row height="xl" width="xl" />
              </SkeletonLoader.Container>
            </SkeletonLoaderTopMarginStyle>
          )}
        </div>
        <div
          style={{
            display: `${document === "Supporting" ? "block" : "none"}`,
          }}
        >
          <StyledTextHeading tag="h2" size="md" tabIndex={-1} ref={handleRef}>
            Supporting documents (if requested)
          </StyledTextHeading>
          {!allSupportingCategoriesUploaded() && (
            <StyledTextBody tag="p" size="sm">
              After reviewing the application, we may require additional
              documents to support the application. If so, we'll contact the
              applicant with specific instructions.
            </StyledTextBody>
          )}
          {allSupportingCategoriesUploaded() && (
            <StyledTextBody tag="p" size="sm">
              All supporting documents have been submitted. Select the document 
              type under <strong>Submitted documents</strong> to make any changes.
            </StyledTextBody>
          )}
          {!sectionsLoading && (
            <SupportingDocumentAccordion
              uploadStatus={categoryUploadedStatuses}
              allUploaded={allSupportingCategoriesUploaded()}
            />
          )}
          {sectionsLoading && (
            <SkeletonLoaderTopMarginStyle>
              <SkeletonLoader.Container isLoading={sectionsLoading}>
                <SkeletonLoader.Row height="xl" width="xl" />
              </SkeletonLoader.Container>
            </SkeletonLoaderTopMarginStyle>
          )}
        </div>
      </StyledBoxDropDown>
      <SubmittedSectionStyle>
        <SubmittedStyledTextHeading
          tag="h2"
          size="md"
          tabIndex={-1}
          ref={handleRef}
        >
          Submitted documents
        </SubmittedStyledTextHeading>
        {!sectionsLoading && (
          <>
            {!someCategoriesUploaded() && categoryUploadedStatuses && (
              <StyledTextBody tag="p" size="sm">
                No documents have been submitted yet.
              </StyledTextBody>
            )}
            <SubmittedDocAccordion
              uploadStatus={categoryUploadedStatuses}
              allUploaded={allRequiredCategoriesUploaded()}
            />
          </>
        )}
        {sectionsLoading && (
          <SkeletonLoaderStyle>
            <SkeletonLoader.Container isLoading={sectionsLoading}>
              <SkeletonLoader.Row height="xl" width="xl" />
            </SkeletonLoader.Container>
          </SkeletonLoaderStyle>
        )}
      </SubmittedSectionStyle>
    </>
  );
};

export default TabsExampleTwo;
