import React, { useEffect, useRef } from "react";
import { ContactHoursContent, ContactHoursText, EmailText, EmailLink, ContactUsContainer } from "./ContactUsStyled";

const ContactUs: React.FC = () => {

  const headingRef = useRef<HTMLHeadingElement>(null)
  useEffect(() => {
    headingRef.current?.focus()
  }, [])
  return (
    <ContactUsContainer role="group" ref={headingRef} tabIndex={-1}>
      <ContactHoursText role="menuitem">Contact Hours</ContactHoursText>
      <ContactHoursContent role="menuitem" aria-label="Mon through Fri">Mon - Fri</ContactHoursContent>
      <ContactHoursContent role="menuitem" >8:00 am - 5:00 pm CT</ContactHoursContent>
      <EmailText>Email</EmailText>
      <EmailLink id="contactUsEmail" href="mailto:credit.line@ally.com">Credit.Line@ally.com</EmailLink>
    </ContactUsContainer>
  );
};

export default ContactUs;
