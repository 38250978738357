import { Button, TextBody, TextHeading, useModal } from "@ally/metronome-ui";
import React from "react";
import { ExitButtonSection, ExitLink, ExitModalContent, ExitModalSection } from "./ExitModalStyled";

export const modalRef = React.createRef<HTMLElement>()

const ExitModal: React.FC = (mb) => {
  const { closeModal } = useModal();

  React.useEffect(() => {
    window.allytm.event('modalView', "Upload Documents:Exit Submit Files", { treatAsPageview: true });
  }, []);
  
  const goBackButtonClick = () => {
    closeModal();
  }

  const exitButtonClick = () => {
    window.location.href = "https://www.ally.com/dealer/";
  }

  return(
    <ExitModalContent>
      <TextHeading tabIndex={-1} size={"md"}>
        Submit Your Files
      </TextHeading>
      <ExitModalSection>
        <TextBody tag={"p"} size={"sm"}>
          Exiting now will erase any files you've uploaded but haven't yet submitted.
          Select <strong>Go Back</strong> to submit the files for review.
        </TextBody>
      </ExitModalSection>
      <ExitButtonSection>
        <Button
          allytmln="GoBackBtn"
          variant="primary"
          content="Go Back"
          onClick={goBackButtonClick}
        />
        <ExitLink
          allytmln="ConfirmExitLink" 
          variant="link" 
          content="Exit" 
          onClick={exitButtonClick} />
      </ExitButtonSection>
    </ExitModalContent>
  );
}

export default ExitModal;
