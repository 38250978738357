import { useAccordion } from "@ally/metronome-ui";
import { DocumentCategory, DocumentCategoryAriaLabels } from "../../Model/Constants/DocumentCategory";
import { AccordionContentStyle } from "../RequiredDocAccordionStyled";
import { UploadFileComponent } from "../UploadFileComponent";
import { BankFileUploadParagraphStyle } from "./BankStatementAccordionStyled";

const BankStatementAccordion = () => {
  const { handleFocus } = useAccordion();
  const bankContent = `If the financial statements provided have not been audited or reviewed by a CPA, bank statements for the last three months will also be required for each entity that’s applying.`;
  return (
    <>
      <AccordionContentStyle>
        <BankFileUploadParagraphStyle ref={handleFocus} tabIndex={-1}>
          {bankContent}
        </BankFileUploadParagraphStyle>
      </AccordionContentStyle>
      <UploadFileComponent
        from={DocumentCategory.BankStatement}
        arialLabel={DocumentCategoryAriaLabels.BankStatementAriaLabel}
      />
    </>
  );
};

export default BankStatementAccordion;
