import {
  Box,
  DropdownMenu,
  Select,
  Tabs,
  TextBody,
  TextHeading
} from "@ally/metronome-ui";
import styled from "styled-components";

export const StyledTab = styled(Tabs.TabWithPanel)`
  border: none;
  background: #f9f9f9;
  box-shadow: none;
  background-color: #f9f9f9;
  margin-left: 43px;
  padding: 0;
  color: #0071c4;
  font-weight: bold;
  font-size: 16px;
  @media (max-width: 767px) {
    margin-left: 20px;
  }
  @media (max-width: 639px) {
    display: none;
  }
  &[data-selected] {
    background-color: #f9f9f9;
    color: #650360;

    ::after {
      position: relative;
      border-bottom: 4px solid #650360;
      display: block;
      padding: inherit;
      padding-bottom: 2px;
    }
  }
  
  &[data-reach-tab-panel] {
    outline: none;
  }
`;

export const StyledTextHeading = styled(TextHeading)`
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  margin-top: 10px;

  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

export const SubmittedStyledTextHeading = styled(TextHeading)`
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (max-width: 767px) {
    font-size: 18px;
  }

`;

export const StyledTextBody = styled(TextBody)`
  margin-top: 16px;
  font-size: 14px;
  line-height: 24px;
  width: 548px;

  strong {
    font-weight: bold;
  }

  @media (max-width: 767px) {
    width: 90vw;
    font-size: 14px;
    margin-bottom: 0;
  }
`;

export const StyledBoxDropDown = styled(Box)`
  display: none;

  @media (max-width: 639px) {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 95vw;
    margin-top: 20px;
    margin-left: 20px;
  }
`;

export const StyledBoxDropdownTrigger = styled(DropdownMenu.Trigger)`
  background-color: #ffffff;
  color: #2a2a2a;
  border: 1px solid #959595;
  border-radius: 0;
  box-sizing: border-box;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  margin-left: 43px;
  
`;

export const SelectedDropdownTrigger = styled(Select)`
background-color: #ffffff;
width: 95%;
`

export const MobileStyledTextHeading = styled(TextHeading)`
height: 23.4px;
font-size: 18px;
font-weight: bold;
line-height: 30px;
margin-top: 10px;
font-weight: bold;
letter-spacing: 0;
line-height: 23.4px;
`

export const MobileDocumentTypeStyle = styled.p`
font-weight: bold;
font-size: 11px;
line-height: 19.8px;
color: #000000;
`

export const MobileAccordionStyle = styled.div`
margin-right: 100px;
height: 100%;
`

export const SubmittedSectionStyle = styled.div`
margin-left: 42px;

@media (max-width: 767px) {
  margin-left: 20px;
  margin-bottom: 20px;
  width: 95%;
  margin-top: 

}
`

export const SkeletonLoaderStyle = styled.div`
  width: 564px;
  margin-bottom: -20px; // "undo" unsightly 20px bottom margin

  @media (max-width: 639px) {
    width: 95%;
  }
`

export const SkeletonLoaderTopMarginStyle = styled.div`
  margin-top: 24px;
  margin-bottom: -20px; // "undo" unsightly 20px bottom margin
  width: 564px;

  @media (max-width: 639px) {
    width: 95%;
  }
`
