import { useAccordion } from "@ally/metronome-ui";
import { DocumentCategory, DocumentCategoryAriaLabels } from "../../Model/Constants/DocumentCategory";
import {
  RequiredFileUploadParagraphStyle,
  RequiredFileUploadHeading,
  RequiredFileUploadUnorderedListStyle,
  RequiredFileUploadListStyle,
  AccordionContentStyle,
} from "../RequiredDocAccordionStyled";
import { UploadFileComponent } from "../UploadFileComponent";

const TwoPreviousYearEndStatement: React.FC = () => {
  const { handleFocus } = useAccordion();

  const mainContent =
    "Upload year-end financial statements for the two previous years for each entity that’s applying. CPA-audited statements are preferred. Acceptable statement types are shown here in descending order of preference.";

  const requiredBulletOne = "CPA-audited statements";

  const acceptedBulletOne = "CPA-reviewed statements";
  const acceptedBulletTwo = "CPA-compiled statements";
  const acceptedBulletThree = "Tax returns";
  const acceptedBulletFour = "Company-prepared statements";

  const secondContent =
    "If the applicant provides one of the following documents, we’ll also require bank statements for the last three months for each entity that’s applying. To provide bank statements, select the Supporting Documents tab, then select Bank Statements.";

  const additionalBankStatement = "Accepted with additional bank statements";

  return (
    <>
      <AccordionContentStyle>
        <RequiredFileUploadParagraphStyle ref={handleFocus} tabIndex={-1}>
          {mainContent}
        </RequiredFileUploadParagraphStyle>
        <RequiredFileUploadHeading>Preferred</RequiredFileUploadHeading>
        <RequiredFileUploadUnorderedListStyle>
          <RequiredFileUploadListStyle>
            {requiredBulletOne}
          </RequiredFileUploadListStyle>
        </RequiredFileUploadUnorderedListStyle>

        <RequiredFileUploadHeading>
          {additionalBankStatement}
        </RequiredFileUploadHeading>
        <RequiredFileUploadParagraphStyle>
          {secondContent}
        </RequiredFileUploadParagraphStyle>
        <RequiredFileUploadUnorderedListStyle>
          <RequiredFileUploadListStyle>
            {acceptedBulletOne}
          </RequiredFileUploadListStyle>
          <RequiredFileUploadListStyle>
            {acceptedBulletTwo}
          </RequiredFileUploadListStyle>
          <RequiredFileUploadListStyle>
            {acceptedBulletThree}
          </RequiredFileUploadListStyle>
          <RequiredFileUploadListStyle>
            {acceptedBulletFour}
          </RequiredFileUploadListStyle>
        </RequiredFileUploadUnorderedListStyle>
      </AccordionContentStyle>
      <UploadFileComponent
        from={DocumentCategory.PreviousYearEndFinancialStatement}
        arialLabel={DocumentCategoryAriaLabels.PreviousYearEndFinancialStatementAriaLabel}
      />
    </>
  );
};

export default TwoPreviousYearEndStatement;
