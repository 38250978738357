import { TextBody, useAccordion } from "@ally/metronome-ui";
import { OtherHelpContentStyle, FinanceAndLeaseLink } from "../ResourcedAccordionStyled";

const OtherHelpfulInformation: React.FC = () => {
  const { handleFocus } = useAccordion();

    return (
      <>
        <OtherHelpContentStyle ref={handleFocus} tabIndex={-1} tag="p" size="sm" lineHeight="21px" weight="bold" color="#0071C4">
          <FinanceAndLeaseLink href='https://www.ally.com/dealer/auto-finance/business-finance-and-lease/' id="documentsYouMayNeed" rel="noreferrer noopener" target="_blank">
          Ally Business Vehicle Financing
          </FinanceAndLeaseLink>
        </OtherHelpContentStyle>
        <TextBody tag="p" size="sm" lineHeight="21px" color="#2A2A2A">
          Quick facts and straight answers about financing choices.
        </TextBody>
      </>
    );
  };

export default OtherHelpfulInformation