export enum DocumentCategory {
  CreditLineApplication = 'CreditLineApplication',
  CurrentYearFinancialStatement = 'CurrentYearFinancialStatement',
  PreviousYearEndFinancialStatement = 'PreviousYearEndFinancialStatement',
  GuarantorInformation = 'GuarantorInformation',
  BankStatement = 'BankStatement',
  CrossDefaultCrossCollateralizationAgreement = 'CrossDefaultCrossCollateralizationAgreement',
  AdditionalDocument = 'AdditionalDocument'
}

export enum DocumentCategoryAriaLabels {
  CreditLineApplicationAriaLabel = 'Upload ally commercial services credit line application',
  CurrentYearFinancialStatementAriaLabel = 'upload current year financial statements',
  PreviousYearEndFinancialStatementAriaLabel = ' Upload Two Previous Year-End Financial Statements',
  GuarantorInformationAriaLabel = 'upload guarantor information',
  BankStatementAriaLabel = 'upload bank statement',
  CrossDefaultCrossCollateralizationAgreementAriaLabel = 'Upload Cross Default / Cross Collateralization Agreement',
  AdditionalDocumentAriaLabel = 'upload additional document'
}
