export class StringFormatUtility {
  public static generateFileSizeString(bytes: number): string {
    const roundToTwoDecimalPlaces = (number: number) => {
      return Math.round((number + Number.EPSILON) * 100) / 100;
    }

    // If bytes are smaller than 1 MB, return size in KB
    if (bytes < 1024 * 1024) {
      const kilobytes = bytes / 1024;

      return `${roundToTwoDecimalPlaces(kilobytes)} KB`;
    }

    // Otherwise, return size in MB
    else {
      const megabytes = bytes / (1024 * 1024);
      return `${roundToTwoDecimalPlaces(megabytes)} MB`;
    }
  }
}

